import { HttpParams } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorService } from '../../behavior.service';
 
@Component({
  selector: 'app-review-request',
  templateUrl: './review-request.component.html',
  styleUrls: ['./review-request.component.css']
})
export class ReviewRequestComponent implements OnInit {

  @Output() dataEvent = new EventEmitter<string>();
  @Output() negotiatePrice = new EventEmitter<Number>();
  @Output() reviewData = new EventEmitter<any>();

  @Input() agentId;
  @Input() changedCurrency: any;
  @Input() prospectingID: any;
  @Input() reviewSubmitted;
  @Input() request_id: any;
  @Input() reviewsData: any;
  review_report_currency_conversion_date: any
  reviewForm: FormGroup;
  addetionalCost: number = 0
  addReviewSubmitted: boolean = false;
  prospectData: any;
  localUserData: any;
  additional_cost: FormArray;
  negotiated_terms: FormArray;
  negotiatedPrice: any = '';
  markupValue: number = 0;
  totalSum: number = 0;
  totalListingDealerPrice: any = 0;
  totalNegotiatedSum: any = 0;
  bonus: any;
  negotiaedPurchasePrice: number = 0;

  constructor(
    private router: Router,
    private _activateRouter: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private bs:BehaviorService
  ) { }

  get rf() { return this.reviewForm.controls }

  ngOnInit() {
    this.reviewForm = this.formBuilder.group({

      purchase_description: ['', Validators.required],
      currency: ['', Validators.required],
      listing_price: [0, Validators.required],
      negotiated_price: [0, Validators.required],
      dealer_price: [0, Validators.required],
      amount: [0],
      description: [''],
      discountNumber: [false],
      discount: [0],
      additional_cost_total: [0],
      discountInNum: [0],
      negotiated_terms: this.formBuilder.array([this.createNegotiateTerms()]),
      additional_cost: this.formBuilder.array([this.createCost()]),
      NegotiatedDiscount: [0],
      DiscountedPercent: [0],
      review_report_currency_conversion_rate: [0],
      review_report_currency_conversion_date: ['']

    })
    // console.log('this.reviewsData', this.reviewsData)
    if (localStorage.getItem('credentials')) {
      this.localUserData = JSON.parse(localStorage.getItem('credentials'))
    }
    if (this.changedCurrency) {


      // if(this.localUserData.from_currency_code && this.localUserData.to_currency_code){

      // }
    }

    if (this.reviewSubmitted) {
      this.getProspectiveDetails();
    } else if (this.prospectingID) {
      this.getProspectingData()
    }







    if (this.changedCurrency == 'euro to usd') {
      this.reviewForm.patchValue({
        'currency': 'USD'
      })

    }
    if (this.changedCurrency == 'usd to euro') {
      this.reviewForm.patchValue({
        'currency': 'EURO'
      })
    }
    if (this.changedCurrency == 'usd to kr') {
      this.reviewForm.patchValue({
        'currency': 'kr'
      })
    }
  }


  confirmPayment(ID, key) { 
    let data = {
      prospecte_id: this.prospectingID,
      key: key
    }
    this.commonService.update('admin/selfassign/prospecte/update-status', data).subscribe(res => {
      if (res) { 
        // this.getProspectiveDetails()
        this.modalService.dismissAll()
        this.toastr.success(res.message)
        this.bs.setDirestion('showData')
      } else { 

      }
    })

  }

  addFeature(): void {
    this.additional_cost = this.reviewForm.get('additional_cost') as FormArray;
    this.additional_cost.push(this.createCost());
  }
  createCost():

    FormGroup {
    return this.formBuilder.group({
      amount: [''],
      description: [''],
    })
  }

  removeWebsiteFunc(index: number) {
    this.additional_cost.removeAt(index);
    let expenses = this.reviewForm.value.additional_cost

    let amount = 0
    expenses.map(itm => {
      amount = Number(amount) + Number(itm.amount)
    })
    let purchasedPrice = this.reviewForm.value.negotiated_price + this.reviewForm.value.dealer_price + this.reviewForm.value.additional_cost;
    let value = Number(this.totalNegotiatedSum) + Number(amount)

    this.addetionalCost = amount;
    this.reviewForm.patchValue({
      additional_cost_total: this.addetionalCost,
      DiscountedPercent: this.negotiatedPrice,
      NegotiatedDiscount: this.bonus
    })

    this.negotiatePrice.emit(value);
    this.reviewData.emit(this.reviewForm.value)

  }

  addNegoitateTerms(): void {
    this.negotiated_terms = this.reviewForm.get('negotiated_terms') as FormArray;
    this.negotiated_terms.push(this.createNegotiateTerms());
  }

  createNegotiateTerms():

    FormGroup {
    return this.formBuilder.group({
      terms: [''],
    })
  }

  removeNegoitateTerms(index: number) {
    this.negotiated_terms.removeAt(index);
  }


  getProspectiveDetails() {
    this.spinner.show();
    let params = new HttpParams().set('?prospecte_id', this.prospectingID).set('procurement_agent_id', this.agentId)
    this.commonService.getWithParms('pagent/assigned/submitreview', params).subscribe((res: any) => {

      if (res.code == 200) {
        this.prospectData = res.data;
        console.log(this.prospectData,'this.prospectData');
        
        console.log('refddf=====>>', this.prospectData);

        this.reviewForm.patchValue({

          purchase_description: this.prospectData.purchase_description,
          currency: this.prospectData.currency_code,
          listing_price: this.prospectData.listing_price,
          negotiated_price: this.prospectData.negotiated_price,
          dealer_price: this.prospectData.dealer_price,
          additional_cost: this.prospectData.additional_cost,
          review_report_currency_conversion_date: this.prospectData ? this.prospectData.review_report_currency_conversion_date : ''

        })
        this.negotiaedPurchasePrice = this.prospectData.purchase_price ? this.prospectData.purchase_price : 0
        this.negotiatedPrice = Number(this.reviewForm.value.listing_price) - Number(this.reviewForm.value.negotiated_price);
        this.totalListingDealerPrice = Number(this.reviewForm.value.listing_price) + Number(this.reviewForm.value.dealer_price);
        this.totalNegotiatedSum = Number(this.reviewForm.value.negotiated_price) + Number(this.reviewForm.value.dealer_price);
        this.bonus = (this.negotiatedPrice * 100) / this.reviewForm.value.listing_price;


        if (this.prospectData.negotiated_terms) {
          this.negotiated_terms = this.reviewForm.get('negotiated_terms') as FormArray;
          let negotiatedData = this.prospectData.negotiated_terms


          let NegotiatedArray = []
          negotiatedData.forEach(element => {
            NegotiatedArray.push({
              terms: element.terms ? element.terms : ''
            })
          });

          NegotiatedArray.forEach(x => {
            this.negotiated_terms.push(this.formBuilder.group(x));
          });
          if (negotiatedData.length >= 1) {
            this.negotiated_terms.removeAt(0)
          } else if (negotiatedData.length == 0) {
            this.negotiated_terms.push(this.createNegotiateTerms())
          }
        }
        if (this.prospectData.additional_cost) {
          this.additional_cost = this.reviewForm.get('additional_cost') as FormArray;
          let additionaldData = this.prospectData.additional_cost


          let additionaldArray = []
          additionaldData.forEach(element => {
            additionaldArray.push({
              amount: element.amount ? element.amount : '',
              description: element.description ? element.description : ''
            })
          });

          additionaldArray.forEach(x => {
            this.additional_cost.push(this.formBuilder.group(x));
          });
          if (additionaldData.length >= 1) {
            this.additional_cost.removeAt(0)
          } else if (additionaldData.length == 0) {
            this.additional_cost.push(this.createCost())
          }
        }
        this.convertCurrency()

      }
    });
  }



  getProspectingData() {
    let data = new HttpParams().set('?id', this.prospectingID).set('userId', this.localUserData.id)
    this.commonService.getWithParms('prospecte_user', data).subscribe((response) => {
      if (response.code == 200) {
        this.prospectData = response.data;
        console.log('refddf', this.prospectData);

        this.reviewForm.patchValue({

          purchase_description: this.prospectData.purchase_description,
          currency: this.prospectData.review_report_currency_code,
          listing_price: this.prospectData.listing_price,
          negotiated_price: this.prospectData.negotiated_price,
          dealer_price: this.prospectData.dealer_price,
          review_report_currency_conversion_date: this.prospectData ? this.prospectData.review_report_currency_conversion_date : ''

        })
        this.negotiaedPurchasePrice = this.prospectData.purchase_price ? this.prospectData.purchase_price : 0
        this.negotiatedPrice = Number(this.reviewForm.value.listing_price) - Number(this.reviewForm.value.negotiated_price);
        this.totalListingDealerPrice = Number(this.reviewForm.value.listing_price) + Number(this.reviewForm.value.dealer_price);
        this.totalNegotiatedSum = Number(this.reviewForm.value.negotiated_price) + Number(this.reviewForm.value.dealer_price);
        this.bonus = (this.negotiatedPrice * 100) / this.reviewForm.value.listing_price;
        if (this.prospectData.negotiated_terms) {
          this.negotiated_terms = this.reviewForm.get('negotiated_terms') as FormArray;
          let negotiatedData = this.prospectData.negotiated_terms


          let NegotiatedArray = []
          negotiatedData.forEach(element => {
            NegotiatedArray.push({
              terms: element.terms ? element.terms : ''
            })
          });

          NegotiatedArray.forEach(x => {
            this.negotiated_terms.push(this.formBuilder.group(x));
          });
          if (negotiatedData.length >= 1) {
            this.negotiated_terms.removeAt(0)
          } else if (negotiatedData.length == 0) {
            this.negotiated_terms.push(this.createNegotiateTerms())
          }
        }
        if (this.prospectData.additional_cost) {
          this.additional_cost = this.reviewForm.get('additional_cost') as FormArray;
          let additionaldData = this.prospectData.additional_cost

          let amount = 0
          let additionaldArray = []
          additionaldData.forEach(element => {
            additionaldArray.push({
              amount: element.amount ? element.amount : '',
              description: element.description ? element.description : ''
            })

            amount = Number(amount) + Number(element.amount)

          });
          this.addetionalCost = amount



          additionaldArray.forEach(x => {
            this.additional_cost.push(this.formBuilder.group(x));
          });
          // this.note.removeAt(0) 
          if (additionaldData.length >= 1) {
            this.additional_cost.removeAt(0)
          } else if (additionaldData.length == 0) {
            this.additional_cost.push(this.createCost())
          }
        }
        this.convertCurrency()
        this.reviewData.emit(this.reviewForm.value)

      }
    }, error => {
      this.spinner.hide();
    });


  }





  reviewSubmit() {
    if (this.prospectingID == '') {
      this.toastr.warning('Please save some details before submitting the request!', 'Warning');
      return;
    }
    this.addReviewSubmitted = true;


    // console.log('this.reForm', this.reviewForm)

    if (this.addReviewSubmitted && !this.reviewForm.invalid) {
      this.spinner.show();

      let data = this.reviewForm.value;



      data['prospecte_id'] = this.prospectingID,
        data['requestId'] = this.request_id


      this.commonService.update('pagent/assigned/submitreview', data).subscribe(res => {
        let resData = res
        if (res.success) {
          this.toastr.success(res.message, 'Success');
          this.reviewSubmitted = true;


          this.dataEvent.emit(this.reviewSubmitted);
        }

        this.spinner.hide()

      },
        error => {
          this.spinner.hide();
          // this.toastr.error(error, 'Error');
        });

    }
  }
  convertCurrency() {

    this.changedCurrency = this.reviewForm.value.currency;
    if (this.reviewForm.value.currency == 'kr') {
      this.reviewForm.value.currency = 'NOK'
    } 
    if(this.reviewForm.value.currency ==null && this.reviewForm.value.currency ==undefined || this.reviewForm.value.currency ==""){
      this.reviewForm.value.currency = 'USD'
    }

    let params = new HttpParams().set('?from', this.reviewForm.value.currency).set('to', 'USD').set('amount', '1');
    this.commonService.getWithParms('rate/allRateConversion', params).subscribe(res => {
      if (res.success) {

        this.totalNegotiatedSum = Number(Number(this.totalNegotiatedSum) * Number(res.data)).toFixed(0)
        console.log('convertedddd', res);
        this.reviewForm.patchValue({
          review_report_currency_conversion_rate: res.data,
          review_report_currency_conversion_date: new Date()
        })
        this.totalListingDealerPrice = Number(Number(this.totalListingDealerPrice) * Number(res.data)).toFixed(0)
        this.review_report_currency_conversion_date = this.reviewForm.value.review_report_currency_conversion_date
      } else {
        this.toastr.error(res.error)

      }
    });

  }


  priceCalculateNegotiatePrice() {
    this.negotiatedPrice = Number(this.reviewForm.value.listing_price) - Number(this.reviewForm.value.negotiated_price);
    this.totalListingDealerPrice = this.reviewForm.value.listing_price + this.reviewForm.value.dealer_price;
    // this.convertCurrency()
  }

  priceCalculateTotalNegotiatePrice() {
    this.totalNegotiatedSum = Number(this.reviewForm.value.negotiated_price) + Number(this.reviewForm.value.dealer_price);
    // this.convertCurrency()
  }
  priceCalculateProc(val) {
    // console.log('fdfd');
    // console.log(val, 'valllllll===>>>');

    let expenses = this.reviewForm.value.additional_cost

    let amount = 0
    expenses.map(itm => {
      amount = Number(amount) + Number(itm.amount)
    })
    let purchasedPrice = this.reviewForm.value.negotiated_price + this.reviewForm.value.dealer_price + this.reviewForm.value.additional_cost;
    let value = Number(this.totalNegotiatedSum) + Number(amount)
    this.convertCurrency()

    this.addetionalCost = amount;
    this.reviewForm.patchValue({
      additional_cost_total: this.addetionalCost,
      DiscountedPercent: this.negotiatedPrice,
      NegotiatedDiscount: this.bonus
    })

    this.negotiatePrice.emit(value);
    this.reviewData.emit(this.reviewForm.value)

  }
  bonusPercentageClacuation() {
    if (this.reviewForm.value.negotiated_price > this.reviewForm.value.listing_price) {
      this.bonus = 0
    } else {
      this.bonus = (this.negotiatedPrice * 100) / this.reviewForm.value.listing_price;
    }
    this.reviewForm.patchValue({
      NegotiatedDiscount: this.negotiatedPrice,
      DiscountedPercent: this.bonus
    })
  }




  ngOnChanges(changes: SimpleChanges): void {

    if (changes.reviewSubmitted) {
      if (changes.reviewSubmitted.currentValue != false) {


        this.getProspectiveDetails();
      }



    }


  }

  checkOnlyDiscount(value) {
    if (value == true) {
      let discountValue = this.reviewForm.controls.discount.value
      this.reviewForm.controls.discountInNum.setValue(discountValue)
    } else {


      this.reviewForm.controls.discountInNum.setValue(0)

    }
    this.calculatorFunc();

  }

  calculatorFunc() {
    let percent: any;
    let discountValue = 0;
    this.markupValue = 0
    this.totalSum = 0;
    if (this.reviewForm.controls.discountNumber.value == false) {
      this.reviewForm.controls.discountInNum.setValue(0)

      percent = (this.markupValue / 100) * this.negotiatedPrice;
      this.reviewForm.controls.discount.setValue(percent.toFixed(0))

      discountValue = this.reviewForm.controls.discount.value;
    } else {

      let discountDataValue = this.reviewForm.controls.discountInNum.value
      if (discountDataValue > 0) {
        let marginInNumGetValue = this.negotiatedPrice - discountDataValue
        this.markupValue = Number(marginInNumGetValue)
        this.reviewForm.controls.markup.setValue(marginInNumGetValue.toFixed(0))
      }
      discountValue = this.reviewForm.controls.discountInNum.value;
    }
  }
}
