import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray} from '@angular/forms';
import {CommonService} from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr'; 
import { HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'ngx-google-places-autocomplete/objects/address'; 
import { GooglePlaceDirective } from "ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-add-bank-details',
  templateUrl: './add-bank-details.component.html',
  styleUrls: ['./add-bank-details.component.css']
})
export class AddBankDetailsComponent implements OnInit {
  @Input() bankDetails:any=''
  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;
  bsubmitted:boolean=false;
  public bankForm: FormGroup
  roleID: any;
  countries: any;
	states: any = [];
  options:any
	cities: any = [];
  public _host = environment.url;
	country: any = "";
	countryShortName: any;
	state: any = "";
	city: any = "";
  pageNo:any=''
  modelsData: any;
  id:any=''
  submitLoader:boolean=false
	bankAccounts: any = [];
	bankEdit: any = false;
	bankEditId: any = '';
/*--add contact person---*/	
showContactPersonSection: boolean = false
@ViewChild('uploadDocs', { static: false }) uploadDocs;
@ViewChild('contactProfileInput', { static: false }) contactProfileInput;
contactSubmitted: boolean = false;
  contactListValueArray = []
  dealerId: any = ''
  public roles: Array<any> = [];
roleModal: boolean = false
roleData: any
titleListData: any;
roleTitleSubmitted: boolean = false
addType: any = '';
roleAndTitleForm: FormGroup;
userType: any = 'business';
fileToUpload: File = null;
progress = 0
contactPersonArray: FormArray;
ContactPersonForm: FormGroup;
extraDetails: FormArray;
socialMediaLink: FormArray;
websiteArray: FormArray;
commonConditionalForm: boolean;
commonEditId: any;
loader: boolean = false;
contactPersonData:any;
/*--add contact person---*/	
  constructor( private commonService:CommonService,
    private formBuilder:FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService, 
    private router:Router,
    private _Activate:ActivatedRoute) { 
		this.createBankForm()
	this.contactPersonCreateForm()
      this.pageNo = this._Activate.snapshot.params['page'];
      this.id = this._Activate.snapshot.params['id'];
      let routerdata = this.router.url
	  this.commonService.addBankModal.subscribe(res=>{ 
			// console.log('add modal',res)
		if(res.id!='' && res.id!=null && res.id!=undefined){ 
		//   this.getSingleBankAccounts(res.id)

		} else if((res.id=='' || res.id==null || res.id==undefined) && res.modal==true){
			this.contactPersonArray.clear()
		this.bankForm.reset()
		}
			})
     
    }

  ngOnInit() {
    
    this.getCountries()
    // this.getBankAccounts()
	
    this.roleAndTitleForm = this.formBuilder.group({
      name: ['', [Validators.required]]
    })
    this.getTitleList()
    this.getRoles()
  }
  contactPersonCreateForm() {
    this.ContactPersonForm = this.formBuilder.group({

      contactPersonArray: this.formBuilder.array([this.createContact()])
    });


  }
	createBankForm() {
		this.bankForm = this.formBuilder.group({
			accountFor:["",[Validators.required]],
			owner_name: [""],
			account_number: ["", [Validators.required,Validators.minLength(8)]],
			routing_number: [""],
			swift_code: [""],
			address: this.formBuilder.array([this.createAddressForm()]),
			bank_name: [""],
			bank_address: this.formBuilder.array([this.createAddressForm()])
		});

		// this.addBankForm = this.formBuilder.group({
		// 	owner_name: ["", Validators.required],
		// 	account_number: ["", Validators.required],
		// 	routing_number: ["", Validators.required],
		// 	swift_code: ["", Validators.required],
		// 	address: this.formBuilder.array([this.createAddressForm()]),
		// 	bank_name: ["", Validators.required],
		// 	bank_address: this.formBuilder.array([this.createAddressForm()])
		// });
	}

  createAddressForm(): FormGroup {
		return this.formBuilder.group({
			street: [''],
			zipcode: ["", [Validators.minLength(4)]],
			city: [""],
			state: [""],
			country: [""],
		});
	}

  setFormValue(a) {
		// console.log('bank details 321 1', a)
		this.bankEditId = a.id;
		this.bankEdit = true;
	 
		this.bankForm.patchValue({
			accountFor:a.accountFor,
			owner_name: a.owner_name,
			account_number: a.account_number,
			routing_number: a.routing_number,
			swift_code: a.swift_code,
			address: a.address,
			bank_name: a.bank_name,
			bank_address: a.bank_address
			
		}) 
	

		if (a.contact_persons) {
			this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
			this.showContactPersonSection = true;
			let ContactPersonData = a.contact_persons
			let ContactData = []
			ContactPersonData.forEach(element => {
			 
			  ContactData.push({
				title: element.title ? element.title : '',
				firstName: element.firstName ? element.firstName : '',
				lastName: element.lastName ? element.lastName : '',
				email: element.email ? element.email : '',
				mobileNo: element.phoneNumberDetails ? element.phoneNumberDetails.mobileNo : '',
				countryCode: element.phoneNumberDetails ? element.phoneNumberDetails.countryCode : '+1',
				note: element.note ? element.note : '',
				role: element.role ? element.role : '',
				progressBar: element.progressBar ? element.progressBar : 'false',
				companyId: element.companyId ? element.companyId : '',
				profileImage: element.extraDetails ? element.extraDetails.profileImage : '',
				extraDetailsData: element.documentUpload ? element.documentUpload.extraDetails : [],
				soicialLinkData: element.extraDetails ? element.extraDetails.socialMediaLink : [],

				extraDetails: this.formBuilder.array([]),
				socialMediaLink: this.formBuilder.array([]),
			  })

			});
			// console.log(ContactData, "conatactData")

			if(ContactPersonData.length>=1){
				this.contactPersonArray.removeAt(0)
			}
			ContactData.forEach(x => {
			  let i = ContactData.indexOf(x)
			  this.contactPersonArray.push(this.formBuilder.group(x));
			  this.extraDetails = this.contactPersonArray['controls'][i]['controls']['extraDetails']
			  this.socialMediaLink = this.contactPersonArray['controls'][i]['controls']['socialMediaLink']

			  // console.log('this.extraDetails patch', this.extraDetails)
			  if (x.extraDetailsData != null && x.extraDetailsData != undefined && x.extraDetailsData.length > 0) {
				x.extraDetailsData.forEach(y => { 
				  let yData = {
					docTitle: y.docTitle,
					progressBar: y.progressBar,
					docs: [y.docs]
				  }
				  this.extraDetails.push(this.formBuilder.group(yData))
				});
			  }
			  if (x.soicialLinkData != null && x.soicialLinkData != undefined && x.soicialLinkData.length > 0) {
				x.soicialLinkData.forEach(z => {
				  // console.log('z data', z)
				  let zData = {
					link: z.link ? z.link : '',
				  }
				  this.socialMediaLink.push(this.formBuilder.group(zData))

				});
				// console.log('this.socialMediaLink 33',this.socialMediaLink)
				if (x.soicialLinkData.length==0) {
				  this.socialMediaLink.push(this.createSocialLink())
				} 
			  }

			});

		

		  }
	
	}
 

  get b() {
		return this.bankForm.controls;
	}


  getCountries() {
		this.commonService.get("countrys").subscribe(
			(res) => {
				if (res.success) {
					this.countries = res.data;
				} else {
					this.toastr.error(res.error.message, "Error");
				}
			},
			(error) => {
			}
		);
	}



	getStates(value,element?:any) {
    // console.log('211',this.country);
    
		this.countryShortName = this.countries.find(x => x.name == this.country)
		let data = new HttpParams().set('?shortName', this.countryShortName && this.countryShortName.shortName)

		this.commonService.getWithParms('states', data).subscribe(res => {
			if (res.success) {
				this.states = res.data
				this.setFormValue(element)

			} else {
				this.toastr.error(res.error.message, 'Error');
			}
		},
			error => {
			}
		);
	}
	getCities() {
		let data = new HttpParams().set('?state', this.state).set('shortName', this.countryShortName && this.countryShortName.shortName)

		

		this.commonService.getWithParms('cities', data).subscribe(res => {
			if (res.success) {
				this.cities = res.data
			} else {
				this.toastr.error(res.error.message, 'Error');
			}
		},
			error => {
			}
		);
	}


  AddressChange(address: Address, i) {
		// console.log('address', address.formatted_address, address)
		if (address.photos && address.photos.length > 0) {
			console.dir(address.photos[0].getUrl({ maxHeight: 500, maxWidth: 500 }));
		}
		let formatted_address = address.formatted_address
		// console.log('formatted_address',formatted_address);
		
		let address_components = address.address_components
	 
		for (let index = 0; index < address_components.length; index++) {
			const element = address_components[index];
			if (address_components[index].types[0] == "country") {
				this.country = address_components[index].long_name
				this.getStates(this.country)
				this.bankForm.get('bank_address')['controls'][i]['controls'].country.patchValue(address_components[index].long_name)


			} else if (address_components[index].types[0] == "administrative_area_level_1") {
				this.state = address_components[index].short_name
				this.bankForm.get('bank_address')['controls'][i]['controls'].state.patchValue(address_components[index].short_name)

				setTimeout(() => {
					this.getCities()
				}, 500);
			} else if (address_components[index].types[0] == "locality" || address_components[index].types[0] == "administrative_area_level_3") {
				setTimeout(() => {
					this.bankForm.get('bank_address')['controls'][i]['controls'].city.patchValue(address_components[index].long_name)

				}, 1000);

			} else if (address_components[index].types[0] == "postal_code") {
				this.bankForm.get('bank_address')['controls'][i]['controls'].zipcode.patchValue(address_components[index].long_name)

			}
			this.bankForm.get('bank_address')['controls'][i]['controls'].street.patchValue(formatted_address)

		}
	}
	onnerAddressChange(address: Address, i) {
		// console.log('address', address.formatted_address, address)
		if (address.photos && address.photos.length > 0) {
			console.dir(address.photos[0].getUrl({ maxHeight: 500, maxWidth: 500 }));
		}
		let formatted_address = address.formatted_address
		let address_components = address.address_components
	 
		for (let index = 0; index < address_components.length; index++) {
			const element = address_components[index];
			if (address_components[index].types[0] == "country") {
				this.country = address_components[index].long_name
				this.getStates(this.country)
				this.bankForm.get('address')['controls'][i]['controls'].country.patchValue(address_components[index].long_name)


			} else if (address_components[index].types[0] == "administrative_area_level_1") {
				this.state = address_components[index].short_name
				this.bankForm.get('address')['controls'][i]['controls'].state.patchValue(address_components[index].short_name)

				setTimeout(() => {
					this.getCities()
				}, 500);
			} else if (address_components[index].types[0] == "locality" || address_components[index].types[0] == "administrative_area_level_3") {
				setTimeout(() => {
					this.bankForm.get('address')['controls'][i]['controls'].city.patchValue(address_components[index].long_name)

				}, 1000);

			} else if (address_components[index].types[0] == "postal_code") {
				this.bankForm.get('address')['controls'][i]['controls'].zipcode.patchValue(address_components[index].long_name)

				// this.bankForm.controls.zipcode.setValue(address_components[index].long_name) 
			}
			this.bankForm.get('address')['controls'][i]['controls'].street.patchValue(formatted_address)
		}
	}
	addContactFunc() {
		this.showContactPersonSection = true;
		this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
		if (this.contactPersonArray.length == 0) {
		  this.contactSubmitted = false;
		  this.contactPersonArray.push(this.createContact());
		} else if (this.contactPersonArray.length > 0) {
		  this.contactSubmitted = true;
		  if (this.contactSubmitted && !this.ContactPersonForm.invalid) {
			this.contactListValueArray.push(...this.ContactPersonForm.controls.contactPersonArray.value)
			this.contactSubmitted = false
			this.contactPersonArray.push(this.createContact());
		  }
		}
	  }

  createContact():
  FormGroup {
  return this.formBuilder.group({
	title: ['', [Validators.required]],
	firstName: ['', [Validators.required]],
	lastName: [''],
	email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%. +-]+@[a-z0-9.-]+\\.[a-z]{1,4}.$")]],
	countryCode:['+1'],
	mobileNo: ['', [Validators.required, Validators.minLength(8)]],
	// phonebusiness: ['', [Validators.minLength(10),Validators.maxLength(12)]], 
	note: [''],
	companyId: [''],
	profileImage: [''],
	headingTitle: [''],
	progressBar: ['false'],
	role: ['', [Validators.required]],


	extraDetails: this.formBuilder.array([this.createDocument()]),
	socialMediaLink: this.formBuilder.array([this.createSocialLink()]),
  })

}
createDocument():FormGroup {
return this.formBuilder.group({
  progressBar: ['false'],
  docTitle: [''],
  docs: [[]],
})

}

createSocialLink():
FormGroup {
return this.formBuilder.group({
  link: [''],
})

}
createWebsite():
FormGroup {
return this.formBuilder.group({
  website: [''],
})
}


contactPersonArrayFunc(): FormArray {
    return this.ContactPersonForm.get('contactPersonArray') as FormArray;
  }
  // websiteArrayFunc(): FormArray {
  //   return this.auctionForm.get('websiteArray') as FormArray;
  // }
  contactPersonDocList(empIndex: number): FormArray {
    return this.contactPersonArrayFunc()
      .at(empIndex)
      .get('extraDetails') as FormArray;
  }
  contactPersonSocialList(empIndex: number): FormArray {
    return this.contactPersonArrayFunc()
      .at(empIndex)
      .get('socialMediaLink') as FormArray;
  }

  addWebsiteFunc() {
    this.websiteArray = this.ContactPersonForm.get('websiteArray') as FormArray;

    this.websiteArray.push(this.createWebsite());
  }

  removeWebsiteFunc(i: number) {
    this.websiteArray.removeAt(i);
  }

  addContactDocs1(empIndex: number) {
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
    this.extraDetails = this.contactPersonArray['controls'][empIndex]['controls']['extraDetails'] as FormArray
    this.extraDetails.push(this.createDocument())
    // console.log('this.contactPersonDocList', this.contactPersonArray['controls'][0]['controls']['extraDetails'])
  }
  addContactSocialLink(empIndex: number) {
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;

    // console.log('this.contactPersonArray 31', this.contactPersonArray)
    this.socialMediaLink = this.contactPersonArray['controls'][empIndex]['controls']['socialMediaLink'] as FormArray

    this.socialMediaLink.push(this.createSocialLink())
    // console.log('this.contactPersonDocList', this.contactPersonArray['controls'][0]['controls']['socialMediaLink'])
  }

  getDocArrayControls(index) {
    return (<FormArray>(<FormArray>this.ContactPersonForm.controls.contactPersonArray).controls[index].get('extraDetails')).controls
  }

  removeContacFunc(index) {
    this.contactPersonArray.removeAt(index)
    // this.contactPersonArray.removeAt(this.contactPersonArray.value.findIndex(image => image.id === index) )
  }
  get cf() { return this.ContactPersonForm.controls; }



  uploadContactDocs(files, pIndex, i) {
    if (files) {
      const formData: FormData = new FormData();
      let uploadedImageArray: any = []
      // this.fileToUpload = files.item(0)
      for (let index = 0; index < files.length; index++) {
        let element = files[index];
        uploadedImageArray.push(element)
        this.fileToUpload = uploadedImageArray;
        formData.append('data', this.fileToUpload[index], this.fileToUpload[index].name);
      }
      formData.append('modelName', 'documents');
      // console.log('uploadd array', formData)
      // let object = {
      //   data: files.item(0),
      //   type: 'category'
      // }
      // this.myInputVariable.nativeElement.value = "";


      let docData = []
      docData = this.contactPersonDocList(pIndex).controls[i]['controls']['docs'].value


      this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('true')
      let params = new HttpParams().set('?modelName', 'documents')
      this.commonService.uploadMultipleImage(params, formData).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // // console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            // // console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            // // console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            // // console.log('User successfully created!', event.body);
            if (event.body.success) {

              if (docData.length > 0) {
                docData.push(...event.body.data.imagePath)
                this.contactPersonDocList(pIndex).controls[i]['controls']['docs'].patchValue(docData)


              } else {
                docData = event.body.data.imagePath;
                // console.log(i);
                this.contactPersonDocList(pIndex).controls[i]['controls']['docs'].patchValue(docData)

              }

              // this.auctionForm.patchValue({ image: this.userImage }) 
              this.uploadDocs.nativeElement.value = '';
              this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('false')

            } else {
              window.scrollTo(0, 0);
              this.toastr.error(event.body.error.message, 'Error');
              this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('false')

            }
            setTimeout(() => {
              this.progress = 0;
              this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('false')

            }, 100);

        }

      }, err => {
        this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('false')

        // this.toastr.error('There are some errors, please try again after some time !', 'Error');
      });
    }

  }

  removeUploadFile(index) {
    this.extraDetails.removeAt(index)
  }
  removeSocialLink(index) {
    this.socialMediaLink.removeAt(index)
  }
  removeImage(image, index) {
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;

    // console.log('this.contactPersonArray', this.contactPersonArray)

    let object = {
      Imagename: image,
      type: 'users'
    }
    this.commonService.deleteImages(object).subscribe((res: any) => {
      if (res.success) {
        this.contactPersonArray.controls[index]['controls']['profileImage'].patchValue('')

        this.spinner.hide();
      } else {
        this.toastr.error(res.error.message, 'Error');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  removeDocument(image, ContactIndex, index) {
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
    this.extraDetails = this.contactPersonArray['controls'][ContactIndex]['controls']['extraDetails'] as FormArray

    // console.log('this.extraDetails', this.extraDetails)
    // console.log('this.extraDetails.controls[index]', this.extraDetails.controls[index]['controls']['docs'].value)
    let docValue = this.extraDetails.controls[index]['controls']['docs'].value

    let resultValue = []
    resultValue = docValue.filter(item => item !== image)

    let object = {
      Imagename: image,
      type: 'documents'
    }

    this.commonService.deleteImage(object).subscribe((res: any) => {
      if (res.success) {
        // console.log('this.extraDetails.controls[index]', this.extraDetails.controls[index]['controls']['docs'])
        this.extraDetails.controls[index]['controls']['docs'].setValue(resultValue)
        // this.contactPersonDocList(index).controls[fileIndex]['controls']['docs'].patchValue('')

        this.spinner.hide();
      } else {
        this.toastr.error(res.error.message, 'Error');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }


  /* Function use to remove Crop with crop id */
  removeCommonFunc(type, ID) {
    this.addType = type
    if (!ID || ID == undefined || ID == null) {
      this.toastr.warning('Id is required')
      return
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this data?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }
    ).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();

        let params = new HttpParams().set('?id', ID).set('fieldName', type)
        this.commonService.deleteRecord('common/record', params).subscribe((res: any) => {

          if (res.success) {
            this.toastr.success(res.message)

            if (this.addType == 'title') {
              this.getTitleList()
            } else if (this.addType == 'role') {
              this.getRoles()
            }

          } else {
            this.toastr.error(res.error.message, 'Error');

          }
          this.spinner.hide()
        }, ((error => {
          this.spinner.hide()
        })))

      } else {

      }
    });

  }
  editCommonFunc(type, id) {
    console.log(type,'type')
    console.log(id,'idddd')
    this.addType = type
    this.roleModal = true
    this.commonConditionalForm = true
    this.commonEditId = id
    if (this.addType == 'title') {
      this.getTitleList()
    } else if (this.addType == 'role') {
      this.getRoles()
    }
  }
  addRoleFunc(value) {
    this.addType = value
    this.roleModal = true
    this.commonEditId = ''
    this.commonConditionalForm = false
  }
  get rTF() { return this.roleAndTitleForm.controls }
  roleTitleFormSubmit() {
    this.roleTitleSubmitted = true;
    if (!this.roleAndTitleForm.invalid) {
      let data = {
        name: this.roleAndTitleForm.value.name,
        userType: this.userType
      }
      let titleValue = ''
      if (this.addType == 'title') {
        titleValue = 'Title'
      } else if (this.addType == 'role') {
        titleValue = 'Role'
      }
      if (!this.commonConditionalForm) {
        this.spinner.show()
        this.commonService.add(this.addType, data).subscribe(res => {

          if (res.success) {
            this.toastr.success(titleValue + ' Added Successfully!');
            if (this.addType == 'title') {
              this.getTitleList()
            } else if (this.addType == 'role') {
              this.getRoles()
            }
            // if (this.addType == 'category') {
            //   this.getCategoryFunc()
            // }
            this.roleModal = false
            this.spinner.hide()
            this.roleAndTitleForm.reset()
          } else {
            this.toastr.error(res.error.message, 'Error');
            this.spinner.hide();
          }

          this.roleModal = false
        },
          error => {
            this.spinner.hide();
            this.roleModal = false
            // this.toastr.error(error, 'Error');
          }
        );
      } else {
        data['id'] = this.commonEditId
        this.spinner.show()
        this.commonService.update(this.addType, data).subscribe(res => {

          if (res.success) {
            this.toastr.success(titleValue + ' Edit Successfully!');
            if (this.addType == 'title') {
              this.getTitleList()
            } else if (this.addType == 'role') {
              this.getRoles()
            }
            // if (this.addType == 'category') {
            //   this.getCategoryFunc()
            // }
            this.roleModal = false
            this.commonEditId = ''
            this.spinner.hide()
            this.roleAndTitleForm.reset()
          } else {
            this.toastr.error(res.error.message, 'Error');
            this.spinner.hide();
          }

          this.roleModal = false
        },
          error => {
            this.spinner.hide();
            this.roleModal = false
            // this.toastr.error(error, 'Error');
          }
        );
      }
      // if (this.addType == 'category') {
      //   titleValue='Category'
      // }

    }
  }

  getTitleList() {
    let data = new HttpParams().set('?userType', this.userType)
    this.commonService.getWithParms('title/name', data).subscribe((response) => {
      if (response.data.length == 0) {
        this.titleListData = [];
      } else {
        this.titleListData = response.data;
        if (this.commonEditId != '' && this.commonEditId != null && this.commonEditId != undefined) {
          let commingData = this.titleListData.filter(catData => {
            return catData.id == this.commonEditId
          })
          // console.log('commingData', commingData, this.commonEditId)
          this.roleAndTitleForm.patchValue({
            name: commingData[0].name,
            // parentGroup:commingData[0].parentGroup?commingData[0].parentGroup.id:'',

          })
        }
      }
    });
  }

  getRoles() {
    let data = new HttpParams().set('?userType', this.userType)

    this.commonService.getWithParms('role/name', data).subscribe((response) => {
      if (response.data.length == 0) {
        this.roleData = [];
      } else {
        this.roleData = [];
        this.roles = response.data;
        this.roles.forEach(element => {
          if (element.name != "" && element.name != null && element.name !== undefined) {

            this.roleData.push(element)
          }
        });

        if (this.commonEditId != '' && this.commonEditId != null && this.commonEditId != undefined) {
          let commingData = this.roleData.filter(catData => {
            return catData.id == this.commonEditId
          })
          // console.log('commingData', commingData, this.commonEditId)
          this.roleAndTitleForm.patchValue({
            name: commingData[0].name,
            // parentGroup:commingData[0].parentGroup?commingData[0].parentGroup.id:'',

          })
        }
      }
    });
  }

  uploadContactProfileImages(files: FileList, i) {
    if (files && files[0]) {
      var filesAmount = files.length;
      for (let i = 0; i < filesAmount; i++) {

        this.fileToUpload = files.item(i)
      }
    }
    //  this.fileToUpload = files.item(0)

    // let type = 'blogs'
    // let object = {
    //   data: imageResult.dataURL,
    //   type: 'users'
    // }
    let type: 'users'
    // this.myInputVariable.nativeElement.value = "";
    if (this.fileToUpload) {
      this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;

      this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('true')



      this.commonService.uploadOnlyImage(this.fileToUpload, 'users').subscribe((event: HttpEvent<any>) => {

        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            // // console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            // // console.log('User successfully created!', event.body);
            if (event.body.success) {
              let docData = '';
              docData = event.body.data.imagePath
              this.contactPersonArray.controls[i]['controls']['profileImage'].patchValue(docData)
              // this.auctionForm.patchValue({ image: this.userImage }) 

              this.contactProfileInput.nativeElement.value = '';
              this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('false')
            } else {
              window.scrollTo(0, 0);
              this.toastr.error(event.body.error.message, 'Error');
              this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('false')
            }
            setTimeout(() => {
              this.progress = 0;
              this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('false')
            }, 100);

        }

        // this.imageLoader=false;
      }, err => {
        this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('false')

        // this.toastr.error('There are some errors, please try again after some time !', 'Error');
      });
    }
  }


  saveBankAccount() {
		// console.log("PPPPP", this.bankForm.controls.address['controls'][0]['controls'])
		this.bsubmitted = true;
		if (!this.bankForm.invalid) {
			this.spinner.show();
			let data = { 
				accountFor: this.bankForm.controls.accountFor.value,
				owner_name: this.bankForm.controls.owner_name.value,
				account_number: this.bankForm.controls.account_number.value,
				routing_number: this.bankForm.controls.routing_number.value,
				swift_code: this.bankForm.controls.swift_code.value!=null?this.bankForm.controls.swift_code.value:'',
				address: this.bankForm.controls.address.value,
				bank_name: this.bankForm.controls.bank_name.value,
				bank_address: this.bankForm.controls.bank_address.value,

			};
			let contactData: any;
			if (this.ContactPersonForm.valid) {
			  contactData = this.ContactPersonForm.value.contactPersonArray
			  let contactDataList = [];
			  let contactDocList = [];
			  let socialLinkList = []
			  contactData.forEach(element => {
				if (element.extraDetails.length > 0) {
				  element.extraDetails.forEach(elem => {
					let elemData = {
					  docTitle: elem.docTitle,
					  progressBar: elem.progressBar,
					  docs: elem.docs
					}
					contactDocList.push(elemData)
				  });
				}
				if (element.socialMediaLink.length > 0) {
				  element.socialMediaLink.forEach(elem => {
					let elemData = {
					  link: elem.link,
	
					}
					socialLinkList.push(elemData)
				  });
				}
				let data = {
				  companyId: element.companyId,
				  email: element.email,
				  firstName: element.firstName,
				  lastName: element.lastName,
				  // mobileNo: element.mobileNo,
				  mobileNo: element.countryCode + element.mobileNo,
				  phoneNumberDetails:{
				   mobileNo: element.mobileNo,
				   countryCode:element.countryCode
				  },
				  note: element.note,
				  progressBar: element.progressBar,
				  role: element.role,
				  title: element.title,
				  documentUpload: {
					extraDetails: contactDocList
				  },
				  extraDetails: {
					profileImage: element.profileImage,
					socialMediaLink: socialLinkList
				  }
				}
				contactDataList.push(data)
			  });
			  // console.log('contactData', contactData, contactDataList)
			  if (contactDataList!=null && contactDataList.length>0) {
				data['contact_persons'] = contactDataList

			   
			   }
			 
	
			}
			
			// console.log('data',data);
			 
			if (this.bankEdit) {
				this.updateBankAccount(data)
			 
			}else{
        this.commonService.add("account", data).subscribe(
          (res: any) => {
            if (res.success) {
              this.spinner.hide();
			  this.bankForm.reset()
			  this.bsubmitted=false
			 let datas = { 
				modal: false,
				id:''
			  }
              this.commonService.addBankModal.next(datas)
              this.contactPersonArray.clear()
              this.toastr.success("Bank Account Added successfully!");
              // this.getBankAccounts();
           
            }
          }, err => {
            this.toastr.error(err.message, "Error");
            this.spinner.hide();
			let datas = { 
				modal: false,
				id:''
			  }
            this.commonService.addBankModal.next(datas)
          })
      }

		
		}
		// var body:any  = {
		// 	owner_name: this.bankForm.controls.owner_name.value,
		// 	account_number: Number( this.bankForm.controls.account_number.value),
		// 	routing_number: Number(this.bankForm.controls.routing_number.value),
		// 	swift_code: this.bankForm.controls.swift_code.value,
		// 	address: this.bankForm.controls.address.value,
		// 	bank_name: this.bankForm.controls.bank_name.value,
		// 	bank_address: this.bankForm.controls.bank_address.value,
		// };

	}

	getBankAccounts() {
		this.commonService.get("accounts").subscribe(
			(res: any) => {
				if (res.success) {
					this.bankAccounts = res.data;
				
				}
			})
	}
	// getSingleBankAccounts(id) {
	// 	let params = new HttpParams().set('?id',id)
	// 	this.commonService.getWithParms("account",params).subscribe(
	// 		(res: any) => { 
	// 				this.bankDetails =res[0]
	// 				this.setFormValue(res.data)
	// 				this.getCountries()
	// 				let country = this.bankDetails['address'][0]['country']
	// 			   let countryData = this.countries.find(x => x.name == country)
	// 			     this.country = countryData.name
	// 				 // console.log('this.country single',country,countryData)
	// 				 this.getStates('') 
				
				
				 
	// 			// console.log('this.bankDetails',this.bankDetails)
	// 		})
	// }
  updateBankAccount(body) {
		let data = body
		// console.log('dd',data);
	 
		data['id'] = this.bankEditId
		this.commonService.update(`account`, data).subscribe(
			(res: any) => {
				 
				if (res.success) {
					this.spinner.hide();
					 this.bankForm.reset()
					 this.contactPersonArray.clear()
					 this.bsubmitted=false
					let datas = { 
						modal: false,
						id:''
					  }
					this.commonService.addBankModal.next(datas)
				   
					this.toastr.success("Bank Account Update successfully!");
					// this.getBankAccounts(); 
				 
				  }else{
					let datas = { 
						modal: false,
						id:''
					  }
					this.commonService.addBankModal.next(datas)
				}
			}, err => {
				this.toastr.error(err.message, "Error");
				let datas = { 
					modal: false,
					id:''
				  }
				this.commonService.addBankModal.next(datas)
			})
	}


	
	detailsDataFunc(id) { 
		this.loader = true;
		this.contactPersonData = [];
		let data = new HttpParams().set('?id', id)
		this.commonService.getWithParms('account',data).subscribe((response) => {
		  if (response.data.length == 0) {
			this.contactPersonData = [];
			this.loader = false; 
		  } else {
			
			this.contactPersonData = response.data
			// this.bankForm.get('bank_address')['controls'][0]['controls'].state.patchValue(this.contactPersonData.bank_address.state)
			// this.bankForm.get('address')['controls'][0]['controls'].state.patchValue(this.contactPersonData.address.state)
			this.setFormValue(this.contactPersonData)
		
			this.loader = false; 
		  }
		},error=>{
		  this.loader = false; 
		});
	  }
 
	  

  ngOnChanges(changes: SimpleChanges): void {
    if( changes.bankDetails.currentValue!=''){
		// console.log('changes.bankDetails.currentValue',changes.bankDetails.currentValue)
		this.detailsDataFunc(changes.bankDetails.currentValue.id)
		this.bankDetails = changes.bankDetails.currentValue
    
     this.getCountries()
  
	 let country = this.bankDetails['bank_address'][0]['country']	 
	 let countryData = this.countries.find(x => x.name == country)
	 if(countryData){
		this.country = countryData.name
		this.getStates('',this.bankDetails) 

	 }
	 let country1 = this.bankDetails['address'][0]['country']	 
	 let countryData1 = this.countries.find(x => x.name == country1)
	 if(countryData1){
		this.country = countryData1.name
		this.getStates('',this.bankDetails) 

	 }
	   
	   
	 

    }else{
		this.bankDetails=''
		this.contactPersonData=[]
		
	}
    // console.log('data',changes,changes.bankDetails.currentValue) 
    
  }
}
