import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2'
import { AppStateService } from '../../app-state.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-contact-person',
  templateUrl: './add-contact-person.component.html',
  styleUrls: ['./add-contact-person.component.css']
})
export class AddContactPersonComponent implements OnInit, OnDestroy {
  contactSubmitted: boolean = false;
  contactListValueArray = []
  dealerId: any = ''
  @Output() reviewData = new EventEmitter<any>();

  public roles: Array<any> = [];
  @ViewChild('contactProfileInput', { static: false }) contactProfileInput;
  @ViewChild('uploadDocs', { static: false }) uploadDocs;
  _host = environment.url;
  @Input() headerType;
  @Input() contact;
  @Input() type;
  @Input() vesselData;
  roleModal: boolean = false
  private destroy$ = new Subject<boolean>();
  roleData: any
  titleListData: any;
  roleTitleSubmitted: boolean = false
  addType: any = '';
  roleAndTitleForm: FormGroup;
  userType: any = 'business';
  fileToUpload: File = null;
  progress = 0
  contactPersonArray: FormArray;
  ContactPersonForm: FormGroup;
  extraDetails: FormArray;
  socialMediaLink: FormArray;
  websiteArray: FormArray;
  commonConditionalForm: boolean;
  commonEditId: any;
  addUserType: any
  contactFormFooter: boolean;
  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public appStateSvc: AppStateService,

  ) {
    this.commonService.contactUserType.subscribe((res: any) => {
      this.addUserType = res

    })
    setTimeout(() => {
console.log(this.contact,"contact")



if (this.contact) {
  console.log(this.ContactPersonForm.controls)
  // this.ContactPersonForm.controls.contactPersonArray

  this.ContactPersonForm.controls.contactPersonArray.patchValue(this.contact)

  this.ContactPersonForm.controls.contactPersonArray

  // this.skillsForm.value.skills[index]

  return
  this.contact.forEach(x => {
  if (x.extraDetails.socialMediaLink != null && x.extraDetails.socialMediaLink != undefined && x.extraDetails.socialMediaLink.length > 0) {
    x.extraDetails.socialMediaLink.forEach(z => {
      let zData = {
        link: z.link ? z.link : '',
      }
      this.socialMediaLink.push(this.formBuilder.group(zData))

    });
    if (x.extraDetails.socialMediaLink.length > 2) {
      this.socialMediaLink.push(this.createSocialLink())
    }
}})
 
  return


}
      // this.getDelrData(this.contact)
    },500);
    this.commonService.dealerID.subscribe(res => {
      if (res != null && res != undefined) {
        this.dealerId = res

        // this.contactPersonArray.clear()
        // this.contactPersonArray.push(this.createContact());

      }
    })


  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  ngOnInit() {
    this.contactPersonCreateForm()
    this.roleAndTitleForm = this.formBuilder.group({
      name: ['', [Validators.required]]
    })
    this.getTitleList()
    this.getRoles()
    this.getValue()
    if (this.appStateSvc.stateData && this.appStateSvc.stateData.contactPerson && this.appStateSvc.stateData.contactPerson.contactPersonData) {
      let contactPersonArray  = this.appStateSvc.stateData.contactPerson.contactPersonData
      // this.ContactPersonForm.patchValue({
      //   contactPersonArray: this.appStateSvc.stateData.contactPerson.contactPersonData
      // })
    }
   

    
      
  
  }


    getDelrData(id) {
    let params = new HttpParams().set('?userId', id)

    this.commonService.getWithParms('dealer/contact/person', params).subscribe(res => {
      if (res.success) {
        let data = res.data;

   

        console.log(data, 'this.dealerContact')
      }
    })


  }

  onKeyUp() {

    this.reviewData.emit(this.ContactPersonForm.value)

  }
  getValue() {
    this.appStateSvc.getContactData$.pipe(takeUntil(this.destroy$)).subscribe(val => {
      this.appStateSvc.setState({
        contactPersonData: this.ContactPersonForm
      })
    })
  }
  contactPersonCreateForm() {
    this.ContactPersonForm = this.formBuilder.group({

      contactPersonArray: this.formBuilder.array([this.createContact()])
    });


  }
  get rTF() { return this.roleAndTitleForm.controls }
  createContact():
    FormGroup {
    return this.formBuilder.group({
      title: [null],
      firstName: [''],
      lastName: [''],
      email: ['', [ Validators.email, Validators.pattern("^[a-zA-Z0-9._%. +-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,4}.$")]],
      countryCode: ['+1'],
      mobileNo: ['', [ Validators.minLength(8)]],
      note: [''],
      progressBar: ['false'],
      profileImage: [''],
      headline: [''],
      extraDetails: this.formBuilder.array([this.createDocument()]),
      socialMediaLink: this.formBuilder.array([this.createSocialLink()]),
      role: [null]
    })

  }
  createDocument():
    FormGroup {
    return this.formBuilder.group({
      progressBar: ['false'],
      docTitle: [''],
      docs: [[]],
    })

  }
  createSocialLink():
    FormGroup {
    return this.formBuilder.group({
      link: [''],
    })
  }

  createWebsite():
    FormGroup {
    return this.formBuilder.group({
      website: [''],
    })
  }

  contactPersonArrayFunc(): FormArray {
    return this.ContactPersonForm.get('contactPersonArray') as FormArray;
  }

  contactPersonDocList(empIndex: number): FormArray {
    return this.contactPersonArrayFunc()
      .at(empIndex)
      .get('extraDetails') as FormArray;
  }
  contactPersonSocialList(empIndex: number): FormArray {
    return this.contactPersonArrayFunc()
      .at(empIndex)
      .get('socialMediaLink') as FormArray;
  }

  addWebsiteFunc() {
    this.websiteArray = this.ContactPersonForm.get('websiteArray') as FormArray;

    this.websiteArray.push(this.createWebsite());
  }

  removeWebsiteFunc(i: number) {
    this.websiteArray.removeAt(i);
  }

  addContactDocs1(empIndex: number) {
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
    this.extraDetails = this.contactPersonArray['controls'][empIndex]['controls']['extraDetails'] as FormArray
    this.extraDetails.push(this.createDocument())
  }
  addContactSocialLink(empIndex: number) {
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;

    this.socialMediaLink = this.contactPersonArray['controls'][empIndex]['controls']['socialMediaLink'] as FormArray

    this.socialMediaLink.push(this.createSocialLink())
  }

  getDocArrayControls(index) {
    return (<FormArray>(<FormArray>this.ContactPersonForm.controls.contactPersonArray).controls[index].get('extraDetails')).controls
  }
  addContactFunc() {
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
    if (this.contactPersonArray.length == 0) {
      this.contactSubmitted = false;
      this.contactPersonArray.push(this.createContact());
    } else if (this.contactPersonArray.length > 0) {
      this.contactSubmitted = true;
      if (this.contactSubmitted && !this.ContactPersonForm.invalid) {
        this.contactListValueArray.push(...this.ContactPersonForm.controls.contactPersonArray.value)
        this.contactSubmitted = false
        this.contactPersonArray.push(this.createContact());
      }
    }
  }
  removeContacFunc(index) {

    this.contactPersonArray.removeAt(index)
  }
  get cf() { return this.ContactPersonForm.controls; }



  uploadContactDocs(files, pIndex, i) {
    if (files) {
      const formData: FormData = new FormData();
      let uploadedImageArray: any = []
      // this.fileToUpload = files.item(0)
      for (let index = 0; index < files.length; index++) {
        let element = files[index];
        uploadedImageArray.push(element)
        this.fileToUpload = uploadedImageArray;
        formData.append('data', this.fileToUpload[index], this.fileToUpload[index].name);
      }
      formData.append('modelName', 'documents');



      let docData = []
      docData = this.contactPersonDocList(pIndex).controls[i]['controls']['docs'].value


      this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('true')
      let params = new HttpParams().set('?modelName', 'documents')
      this.commonService.uploadMultipleImage(params, formData).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // // console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            // // console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            // // console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            // // console.log('User successfully created!', event.body);
            if (event.body.success) {

              if (docData.length > 0) {
                docData.push(...event.body.data.imagePath)
                this.contactPersonDocList(pIndex).controls[i]['controls']['docs'].patchValue(docData)


              } else {
                docData = event.body.data.imagePath;
                this.contactPersonDocList(pIndex).controls[i]['controls']['docs'].patchValue(docData)

              }

              this.uploadDocs.nativeElement.value = '';
              this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('false')

            } else {
              window.scrollTo(0, 0);
              this.toastr.error(event.body.error.message, 'Error');
              this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('false')

            }
            setTimeout(() => {
              this.progress = 0;
              this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('false')

            }, 100);

        }

      }, err => {
        this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('false')

      });
    }

  }

  removeUploadFile(index) {
    this.extraDetails.removeAt(index)
  }
  removeSocialLink(index) {
    this.socialMediaLink.removeAt(index)
  }
  removeImage(image, index) {
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;


    let object = {
      Imagename: image,
      type: 'users'
    }
    this.commonService.deleteImages(object).subscribe((res: any) => {
      if (res.success) {
        this.contactPersonArray.controls[index]['controls']['profileImage'].patchValue('')

        this.spinner.hide();
      } else {
        this.toastr.error(res.error.message, 'Error');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  removeDocument(image, ContactIndex, index) {

    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
    this.extraDetails = this.contactPersonArray['controls'][ContactIndex]['controls']['extraDetails'] as FormArray

    let docValue = this.extraDetails.controls[index]['controls']['docs'].value

    let resultValue = []
    resultValue = docValue.filter(item => item !== image)

    let object = {
      Imagename: image,
      type: 'documents'
    }

    this.commonService.deleteImages(object).subscribe((res: any) => {
      if (res.success) {
        this.extraDetails.controls[index]['controls']['docs'].setValue(resultValue)

        this.spinner.hide();
      } else {
        this.toastr.error(res.error.message, 'Error');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }


  /* Function use to remove Crop with crop id */
  removeCommonFunc(type, ID) {
    this.addType = type
    if (!ID || ID == undefined || ID == null) {
      this.toastr.warning('Id is required')
      return
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this data?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }
    ).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();

        let params = new HttpParams().set('?id', ID).set('fieldName', type)
        this.commonService.deleteRecord('common/record', params).subscribe((res: any) => {

          if (res.success) {
            this.toastr.success(res.message)

            if (this.addType == 'title') {
              this.getTitleList()
            } else if (this.addType == 'role') {
              this.getRoles()
            }

          } else {
            this.toastr.error(res.error.message, 'Error');

          }
          this.spinner.hide()
        }, ((error => {
          this.spinner.hide()
        })))

      } else {

      }
    });

  }
  editCommonFunc(type, id) {
    this.addType = type
    this.roleModal = true
    this.commonConditionalForm = true
    this.commonEditId = id
    if (this.addType == 'title') {
      this.getTitleList()
    } else if (this.addType == 'role') {
      this.getRoles()
    }
  }
  addRoleFunc(value) {
    this.addType = value
    this.roleModal = true
    this.commonEditId = ''
    this.commonConditionalForm = false
  }

  roleTitleFormSubmit() {
    this.roleTitleSubmitted = true;
    if (!this.roleAndTitleForm.invalid) {
      let data = {
        name: this.roleAndTitleForm.value.name,
        userType: this.userType
      }
      let titleValue = ''
      if (this.addType == 'title') {
        titleValue = 'Title'
      } else if (this.addType == 'role') {
        titleValue = 'Role'
      }
      if (!this.commonConditionalForm) {
        this.spinner.show()
        this.commonService.add(this.addType, data).subscribe(res => {

          if (res.success) {
            this.toastr.success(titleValue + ' Added Successfully!');
            if (this.addType == 'title') {
              this.getTitleList()
            } else if (this.addType == 'role') {
              this.getRoles()
            }
            // if (this.addType == 'category') {
            //   this.getCategoryFunc()
            // }
            this.roleModal = false
            this.spinner.hide()
            this.roleAndTitleForm.reset()
          } else {
            this.toastr.error(res.error.message, 'Error');
            this.spinner.hide();
          }

          this.roleModal = false
        },
          error => {
            this.spinner.hide();
            this.roleModal = false
            // this.toastr.error(error, 'Error');
          }
        );
      } else {
        data['id'] = this.commonEditId
        this.spinner.show()
        this.commonService.update(this.addType, data).subscribe(res => {

          if (res.success) {
            this.toastr.success(titleValue + ' Edit Successfully!');
            if (this.addType == 'title') {
              this.getTitleList()
            } else if (this.addType == 'role') {
              this.getRoles()
            }
            // if (this.addType == 'category') {
            //   this.getCategoryFunc()
            // }
            this.roleModal = false
            this.commonEditId = ''
            this.spinner.hide()
            this.roleAndTitleForm.reset()
          } else {
            this.toastr.error(res.error.message, 'Error');
            this.spinner.hide();
          }

          this.roleModal = false
        },
          error => {
            this.spinner.hide();
            this.roleModal = false
            // this.toastr.error(error, 'Error');
          }
        );
      }
      // if (this.addType == 'category') {
      //   titleValue='Category'
      // }

    }
  }

  getTitleList() {
    let data = new HttpParams().set('?userType', this.userType)
    this.commonService.getWithParms('title/name', data).subscribe((response) => {
      if (response.data.length == 0) {
        this.titleListData = [];
      } else {
        this.titleListData = response.data;
        if (this.commonEditId != '' && this.commonEditId != null && this.commonEditId != undefined) {
          let commingData = this.titleListData.filter(catData => {
            return catData.id == this.commonEditId
          })
          // console.log('commingData', commingData, this.commonEditId)
          this.roleAndTitleForm.patchValue({
            name: commingData[0].name,
            // parentGroup:commingData[0].parentGroup?commingData[0].parentGroup.id:'',

          })
        }
      }
    });
  }

  getRoles() {
    let data = new HttpParams().set('?userType', this.userType)

    this.commonService.getWithParms('role/name', data).subscribe((response) => {
      if (response.data.length == 0) {
        this.roleData = [];
      } else {
        this.roleData = [];
        this.roles = response.data;
        this.roles.forEach(element => {
          if (element.name != "" && element.name != null && element.name !== undefined) {

            this.roleData.push(element)
          }
        });

        if (this.commonEditId != '' && this.commonEditId != null && this.commonEditId != undefined) {
          let commingData = this.roleData.filter(catData => {
            return catData.id == this.commonEditId
          })
          // console.log('commingData', commingData, this.commonEditId)
          this.roleAndTitleForm.patchValue({
            name: commingData[0].name,
            // parentGroup:commingData[0].parentGroup?commingData[0].parentGroup.id:'',

          })
        }
      }
    });
  }

  uploadContactProfileImages(files: FileList, i) {
    if (files && files[0]) {
      var filesAmount = files.length;
      for (let i = 0; i < filesAmount; i++) {

        this.fileToUpload = files.item(i)
      }
    }
    //  this.fileToUpload = files.item(0)

    // let type = 'blogs'
    // let object = {
    //   data: imageResult.dataURL,
    //   type: 'users'
    // }
    let type: 'users'
    // this.myInputVariable.nativeElement.value = "";
    if (this.fileToUpload) {
      this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;

      this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('true')



      this.commonService.uploadOnlyImage(this.fileToUpload, 'users').subscribe((event: HttpEvent<any>) => {

        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            // // console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            // // console.log('User successfully created!', event.body);
            if (event.body.success) {
              let docData = '';
              docData = event.body.data.imagePath
              this.contactPersonArray.controls[i]['controls']['profileImage'].patchValue(docData)
              // this.auctionForm.patchValue({ image: this.userImage }) 

              this.contactProfileInput.nativeElement.value = '';
              this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('false')
            } else {
              window.scrollTo(0, 0);
              this.toastr.error(event.body.error.message, 'Error');
              this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('false')
            }
            setTimeout(() => {
              this.progress = 0;
              this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('false')
            }, 100);

        }

        // this.imageLoader=false;
      }, err => {
        this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('false')

        // this.toastr.error('There are some errors, please try again after some time !', 'Error');
      });
    }
  }

  addContactPersonSubmit() {
    this.contactSubmitted = true;
    // let value = this.ContactPersonForm.controls.countryCode.value
    // let val = this.ContactPersonForm.controls.mobileNo.value

    // let code = value + val
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;

    if (this.contactSubmitted && !this.ContactPersonForm.invalid) {

      let data = {
        user_id: this.dealerId,

      }
      let contactData: any;
      contactData = this.ContactPersonForm.value.contactPersonArray
      let contactDataList = [];
      let contactDocList = [];
      let socialLinkList = [];
      contactData.forEach(element => {
        if (element.extraDetails.length > 0) {
          element.extraDetails.forEach(elem => {
            let elemData = {
              docTitle: elem.docTitle,
              progressBar: elem.progressBar,
              docs: elem.docs
            }
            contactDocList.push(elemData)
          });
        }
        if (element.socialMediaLink.length > 0) {
          element.socialMediaLink.forEach(elem => {
            let elemData = {
              link: elem.link,

            }
            socialLinkList.push(elemData)
          });
        }
        let datas1 = {
          companyId: element.companyId,
          profileImage: element.profileImage,
          email: element.email,
          firstName: element.firstName,
          lastName: element.lastName,
          mobileNo: element.countryCode +' '+ element.mobileNo,
          phoneNumberDetails: {
            countryCode: element.countryCode,
            mobileNo: element.mobileNo,

          },
          // mobileNo: element.mobileNo,
          note: element.note,
          progressBar: element.progressBar,
          role: element.role,
          title: element.title,
          socialMediaLink: socialLinkList,
          documentUpload: {
            extraDetails: contactDocList,

          },
          extraDetails: {
            // profileImage: element.profileImage,
            // socialMediaLink: socialLinkList,
            ...contactDocList
          }
        }

        // let datas = {
        //     ...this.contactPersonArray.value[0],
        //     companyId: element.companyId, 
        // }

        // contactDataList.push(datas)
      });
      // data['contact_persons'] = contactDataList 
      data['contact_persons'] = this.contactPersonArray.value


      let apiUrl: any = ''
      if (this.addUserType == 'auction') {
        this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;

        apiUrl = 'aduction_house'
        delete data.user_id

        data['id'] = this.dealerId
        this.commonService.update(apiUrl, data).subscribe(res => {
          if (res.success) {

            this.commonService.commoncontactPersonModal.next(false)
            this.contactPersonArray.clear()
            this.contactSubmitted = false

          }
        })
      } else {
        this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
if(!this.contact){
  apiUrl = 'contact/person'
  // this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
  console.log(this.contactPersonArray,'contactPersonArray')
  console.log(data);
  
      // return
        this.commonService.add(apiUrl, data).subscribe(res => {
          if (res.success) {

            this.commonService.commoncontactPersonModal.next(false)
            this.contactPersonArray.clear()
            this.contactSubmitted = false

          }
        })
      }else{
        this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;

        console.log(this.contactPersonArray,this.contact)
        let data={
          id:this.contact[0].id,
          ...this.contactPersonArray.value[0]

        }
        // return
    
          apiUrl = 'edit/contact/person'
        
                this.commonService.add(apiUrl, data).subscribe(res => {
                  if (res.success) {
        
                    this.commonService.commoncontactPersonModal.next(false)
                    this.contactPersonArray.clear()
                    this.contactSubmitted = false
                    this.toastr.success('Data Updated Successfully');
        
                  }
                })
   
      }

      // this.commonService.add(apiUrl, data).subscribe(res => {
      //   if (res.success) {
      //     this.contactPersonArray.clear()
      //     this.commonService.commoncontactPersonModal.next(false)
      //     this.contactSubmitted = false

      //   }
      // })

    }
  }
}
}